body, html {
  background-color: whitesmoke !important;
  height: auto !important;
}

footer {
  background-color: rgba(54, 52, 57, 0.25);
  color: rgba(54, 52, 57, 0.55);
  position: fixed;
  width: 100%;
  z-index: 100;
  bottom: 0;
}

footer > .right {
  position: fixed;
  right: 0;
}

.App-logo{
  max-height: 10vh;
  max-width: 15vw;
}
.App-navbar{
  width: 85vw;
  height: 10vh;
  display: inline-flex;
  position: absolute;
  right: 0;
}
.App-header {
  max-height: 10vh;
  background-color: white;
  border-bottom: 2px solid black;
}

#article {
  margin: 70px 0 0 80px;
  padding: 20px;
}

.modals.inline{
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
}

.modals.small.inline{
  min-height: 20vh;
}

.modals.big.inline{
  height: 50vh;
}

.modal {
  background-color: white;
  border-radius: 10px;
  margin: 0 10px;
  height: 100%;
  width: 100%;
  min-width: 250px;
  display: inline-block;
}

.small.modal {
  flex: 1 1 calc(25% - 20px);
  background-color: white;
  border-radius: 10px;
  margin: 10px;
  height: 150px;
  min-width: 300px;
}

/* Media queries for responsiveness */
@media screen and (max-width: 1400px) {
  .modals.small.inline .modal {
    flex: 1 0 calc(50% - 20px);
    min-width: calc(50% - 20px);
  }
  .modal.w70, .modal.w30 {
    flex: 1 0 calc(50% - 20px);
    min-width: calc(50% - 20px);
  }
}

@media screen and (max-width: 768px) {
  .modals.small.inline .modal {
    flex: 1 0 calc(100% - 20px);
    min-width: 100%;
  }

  .modal.w70, .modal.w30 {
    flex: 1 0 calc(100% - 20px);
    min-width: 100%;
  }

  #article {
    margin: 50px 0 0 0;
  }
}

.modal.w70 {
  background-color: white;
  border-radius: 10px;
  margin: 0 10px 10px 10px;
  height: 100%;
  flex: 7;
  min-width: none;
  display: inline-block;
}

.modal.w30 {
  background-color: white;
  border-radius: 10px;
  margin: 0 10px 10px 10px;
  height: 100%;
  flex: 3;
  display: inline-block;
}

.item::before {
  content: none !important;
}

.left-sidebar > .item {
  display: inline-block;
  white-space: nowrap;
}

.left-sidebar > a.item {
  height: 50px;
  padding: 10px;
}

@keyframes expandHeight {
  from {
    height: 0;
  }
  to {
    height: 60vh;
  }
}

.burger-menu {
  overflow: hidden;
  animation: expandHeight 0.5s ease-in-out forwards;
}

.shadyButton {
  cursor: pointer;
}

.button-container {
  position: absolute;
  top: 115px;
  right: 30px;
  width: 35%;
}

.button-row {
  display: flex;
  justify-content: flex-end;
}

.button-row > button {
  margin-right: 10px;
  width: 200px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
}

.flex-item {
  flex-basis: calc(25% - 10px);
  box-sizing: border-box;
  padding: 5px;
  margin-bottom: 20px;
}

/* Media queries for responsiveness */
@media screen and (max-width: 1400px) {
  .flex-container {
    flex: 1 0 calc(50% - 20px);
    min-width: calc(50% - 20px);
  }
  .flex-item {
    flex: 1 0 calc(50% - 20px);
    min-width: calc(50% - 20px);
  }
  .button-container {
    position: relative;
    top: 10px;
    width: 100%;
  }
  
  .button-row {
    display: flex;
    justify-content: flex-end;
  }
  
  .button-row > button {
    margin-right: 10px;
  }
}

@media screen and (max-width: 768px) {
  .flex-container {
    flex: 1 0 calc(100% - 20px);
    min-width: 100%;
  }

  .flex-item {
    flex: 1 0 calc(100% - 20px);
    min-width: 100%;
  }
  .button-container {
    position: relative;
    top: 10px;
    width: 100%;
  }
  
  .button-row {
    display: flex;
    justify-content: flex-end;
  }
  
  .button-row > button {
    font-size: 10px !important;
  }
}

.sorted {
  cursor: pointer;
}

.sorted.ascending::after {
  content: ' ↑';
}

.sorted.descending::after {
  content: ' ↓';
}

.agenda {
  display: flex;
}

.stuff {
  flex: 0 0 auto;
  width: 35%;
}

.calendar-wrapper {
  flex: 1;
  margin-left: 20px;
}
